import { render, staticRenderFns } from "./orderMap.vue?vue&type=template&id=808bafa8&scoped=true&"
import script from "./orderMap.vue?vue&type=script&lang=js&"
export * from "./orderMap.vue?vue&type=script&lang=js&"
import style0 from "./orderMap.vue?vue&type=style&index=0&id=808bafa8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "808bafa8",
  null
  
)

export default component.exports